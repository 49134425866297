:root {
    --black-color: #000000;

    --bg-color: #ffffff;
    --bg-color-2: #ffffff;
    --bg-color-3: #ffffff;

    --main-color: #000000;
    --main-color-2: #000000;

    --text-color: #000000;
    --text-muted: #000000;

    --track-color: #ffffff;
    --thumb-color: #000000;
    --thumb-hover-color: #000000;

    
   
    

    --text-shadow: 1px 1px 2px rgba($color: #000000, $alpha: 0.8);
}